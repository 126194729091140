<template>
  <div>
    <component-menu></component-menu>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 mb-2">
          <h2>{{$t("lang.application.privacy_policy.name")}}</h2>
          <p class="fw-light">{{$t("lang.application.privacy_policy.detailed_description")}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mb-2">
          <div class="card">
            <div class="card-body">
              <h2 v-html="lang.application.privacy_policy.label.about_analytics.name"></h2>
              <p v-html="lang.application.privacy_policy.label.about_analytics.description"></p>
              <h2 v-html="lang.application.privacy_policy.label.about_advertisement.name"></h2>
              <p v-html="lang.application.privacy_policy.label.about_advertisement.description"></p>
              <h2 v-html="lang.application.privacy_policy.label.purpose_personal_information.name"></h2>
              <p v-html="lang.application.privacy_policy.label.purpose_personal_information.description"></p>
              <h2 v-html="lang.application.privacy_policy.label.disclosure_personal_information.name"></h2>
              <p v-html="lang.application.privacy_policy.label.disclosure_personal_information.description"></p>
              <h2 v-html="lang.application.privacy_policy.label.correspond_personal_information.name"></h2>
              <p v-html="lang.application.privacy_policy.label.correspond_personal_information.description"></p>

              <h2 v-html="lang.application.privacy_policy.label.children.name"></h2>
              <p v-html="lang.application.privacy_policy.label.children.description"></p>
              <h2 v-html="lang.application.privacy_policy.label.eu_and_eea_users_rights.name"></h2>
              <p v-html="lang.application.privacy_policy.label.eu_and_eea_users_rights.description"></p>
              <h2 v-html="lang.application.privacy_policy.label.california_privacy_rights.name"></h2>
              <p v-html="lang.application.privacy_policy.label.california_privacy_rights.description"></p>

              <h2 v-html="lang.application.privacy_policy.label.disclaimer.name"></h2>
              <p v-html="lang.application.privacy_policy.label.disclaimer.description"></p>
              <h2 v-html="lang.application.privacy_policy.label.changes_privacy_policy.name"></h2>
              <p v-html="lang.application.privacy_policy.label.changes_privacy_policy.description"></p>
              <p>{{$t("lang.application.privacy_policy.label.last_updated.name")}}:{{$t("lang.application.privacy_policy.label.last_updated.description")}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component-footer></component-footer>
  </div>
</template>

<script>
  import Vue from 'vue';
  //import $ from 'jquery';
  export default {
    metaInfo : {
      title: `${window.application.title} ${window.application.lang.application.privacy_policy.name}`,
      meta: [
        {name: 'description', conent: `${window.application.lang.application.privacy_policy.meta_description}`}
      ]
    },
    head: {
      link: [
        {rel: 'alternate', hreflang: 'en', href: 'https://ezeful.app/en/privacypolicy'},
        {rel: 'alternate', hreflang: 'es', href: 'https://ezeful.app/es/privacypolicy'},
        {rel: 'alternate', hreflang: 'hi', href: 'https://ezeful.app/hi/privacypolicy'},
        {rel: 'alternate', hreflang: 'ja', href: 'https://ezeful.app/ja/privacypolicy'},
        {rel: 'alternate', hreflang: 'zh-cn', href: 'https://ezeful.app/zh_cn/privacypolicy'},
        {rel: 'alternate', hreflang: 'zh-tw', href: 'https://ezeful.app/zh_tw/privacypolicy'},
      ],
      //script: [
      //  { src: window.application.adsense_script, async: true, crossorigin:"anonymous"},
      //],
    },
    data () {
      return {
        lang: window.application.lang,
      }
    },
    created() {
      Vue.component('component-menu', require('../components/Menu.vue').default);
      Vue.component('component-footer', require('../components/Footer.vue').default);
    },
    mounted() {
      //let self = this;
    },
    updated() {
      //let self = this;
    },
    methods: {

    }
  }
</script>

<style>

  /** Extra Small Screen (col-xs) ********************************************************/
  @media (max-width: 767px) {

  }

  /** Small Screen (col-sm) **************************************************************/
  @media (min-width: 768px) {

  }

  /** Medium Screen (col-md) *************************************************************/
  @media (min-width: 992px) {

  }
  /** Large Screen (col-lg) **************************************************************/
  @media (min-width: 1200px) { /*元は1200px*/

  }

</style>
